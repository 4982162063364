import Navigation from './components/navigation/Navigation';
import Intro from './components/intro/Intro';
import About from './components/about/About';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';

function App() {
  return (
    <div className="App">
      <Navigation />
      <Intro />
      <About />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;

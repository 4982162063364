import i18next from "i18next";
import { initReactI18next } from "react-i18next";

// Import all translation files
import translationEnglish from './translation/english/translation.json';
import translationDutch from './translation/dutch/translation.json';

const resources = {
    en: {
        translation: translationEnglish,
    },
    nl: {
        translation: translationDutch,
    },
}

i18next
.use(initReactI18next)
.init({
    resources,
    lng:"nl", //default language
});

export default i18next;
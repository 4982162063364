import './intro.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-scroll';
import background from '../../assets/image.png';

function Intro() {
    const { t } = useTranslation();

    return (
        <section id="intro" className="intro">
            <div className="intro-content">
                <span className="intro-content-title">{t("Intro.hello")}</span>
                <span className="intro-content-intro">{t("Intro.i'm")} <span className="intro-content-introName">Kevin van Bommel</span> <br/> Frontend Developer</span>
                <p className="intro-content-introText">{t("Intro.about")} <br/> {t("Intro.teamPlayer")}</p>
                <Link to="contact"><button className="intro-content-button">{t("Navigation.contact")}</button></Link>
            </div>
            {/* <img src={background} alt="Profile" className="intro-background" /> */}
        </section>
    );
}

export default Intro;
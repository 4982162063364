import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-scroll';
import './contact.scss';
import FacebookIcon from '../../assets/facebook-icon.png';
import TwitterIcon from '../../assets/twitter.png';
import InstagramIcon from '../../assets/instagram.png';

function Contact() {
    const { t } = useTranslation();
    return (
        <section id="contact" className="contact">
            <h1 className="contact-title">{t("Navigation.contact")}</h1>
            <span className="contact-description">{t(("Contact.description"))}</span>
            <form className="contact-form">
                <input type="text" className="contact-form-name" placeholder={t("Contact.formName")} />
                <input type="email" className="contact-form-email" placeholder={t("Contact.formEmail")} />
                <textarea className="contact-form-message" placeholder={t("Contact.formMessage")} name="message" rows={5} />
                <button type="submit" value="Send" className="contact-form-submit">{t("Contact.formSubmit")}</button>
            </form>
            <div className="contact-form-links">
                <a href="https://facebook.kevay.nl" target="_blank"><img src={FacebookIcon} alt="Facebook" className="contact-form-links-link" /></a>
                <a href="https://twitter.kevay.nl" target="_blank"><img src={TwitterIcon} alt="Twitter" className="contact-form-links-link" /></a>
                <a href="https://insta.kevay.nl" target="_blank"><img src={InstagramIcon} alt="Instagram" className="contact-form-links-link" /></a>
            </div>
        </section>
    );
}

export default Contact;
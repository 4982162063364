import './footer.scss';

function Footer() {
    const year = new Date().getFullYear();

    return (
        <footer className="footer">
            Copyright &#169; {year} Kevin van Bommel
        </footer>
    );
}

export default Footer;
import './about.scss';
import { useTranslation } from 'react-i18next';
import WebsiteBuilder from '../../assets/websitebuilder.png';

function About() {
    const { t } = useTranslation();
    const birthday = new Date("04/15/2001");
    const month_diff = Date.now() - birthday.getTime();
    const age_dt = new Date(month_diff);   
    const year = age_dt.getUTCFullYear();
    const age = Math.abs(year - 1970);

    return (
        <section id="about" className="about">
            <span className="about-title">{t("Navigation.about")}</span>
            <span className="about-description">{t("About.me")} {age} {t("About.years")} <br/> {t("Intro.about")} <br/> {t("About.hobbies")}</span>
            <div className="about-bars">
                <div className="about-bars-bar">
                    <img src={WebsiteBuilder} alt="" className="about-bars-bar-image" />
                    <div className="about-bars-bar-text">
                        <h2>{t("About.websiteBuilder")}</h2>
                        <p>{t("About.websiteBuilderText")}</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
import { useState } from "react";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-scroll';
import ReactCountryFlag from "react-country-flag";
import './navigation.scss';
import logo from '../../assets/logo.png';
import contact from '../../assets/contact.png';
import menu from '../../assets/menu.png';

function Navigation() {
    const [showMenu, setShowMenu] = useState(false);

    const { t, i18n } = useTranslation();

    const onClickLanguageChange = (e: any) => {
        const language = e.target.value;
        i18n.changeLanguage(language); //change the language
    }

    return (
        <nav className="navigation">
            <img src={logo} alt="Logo" className="navigation-logo" />
            <div className="navigation-desktopMenu">
                <Link activeClass="active" to="intro" spy={true} smooth={true} offset={-100} duration={500} className="navigation-desktopMenu-listItem" onClick={() => {setShowMenu(showMenu)}}>{t("Navigation.home")}</Link>
                <Link activeClass="active" to="about" spy={true} smooth={true} offset={-49} duration={500} className="navigation-desktopMenu-listItem" onClick={() => {setShowMenu(showMenu)}}>{t("Navigation.about")}</Link>
                <select className="navigation-desktopMenu-select" onChange={onClickLanguageChange}>
                    <option lang="nl" value="nl"><ReactCountryFlag countryCode="NL" /></option>
                    <option lang="en" value="en"><ReactCountryFlag countryCode="US" /></option>
                </select>
            </div>
            <button className="navigation-desktopMenu-button" onClick={(() => document.getElementById('contact')?.scrollIntoView({behavior: 'smooth'}))}>
                <img src={contact} alt="" className="navigation-desktopMenu-image" />{t("Navigation.contact")}
            </button>
            <img src={menu} alt="" className="navigation-mobileMenu" onClick={() => {setShowMenu(!showMenu)}} />
            <div className="navigation-mobileMenu-navMenu" style={{ display: showMenu? 'flex': 'none' }}>
                <Link activeClass="active" to="intro" spy={true} smooth={true} offset={-100} duration={500} className="navigation-mobileMenu-navMenu-listItem" onClick={() => {setShowMenu(false)}}>{t("Navigation.home")}</Link>
                <Link activeClass="active" to="about" spy={true} smooth={true} offset={-49} duration={500} className="navigation-mobileMenu-navMenu-listItem" onClick={() => {setShowMenu(false)}}>{t("Navigation.about")}</Link>
                <Link activeClass="active" to="contact" spy={true} smooth={true} offset={-49} duration={500} className="navigation-mobileMenu-navMenu-listItem" onClick={() => {setShowMenu(false)}}>{t("Navigation.contact")}</Link>
                <select className="navigation-mobileMenu-navMenu-select" style={{ display: showMenu? 'flex': 'none' }} onChange={onClickLanguageChange}>
                    <option lang="nl" value="nl">Dutch</option>
                    <option lang="en" value="en">English</option>
                </select>
            </div>
        </nav>
    );
}

export default Navigation;